@tailwind base;

@layer base {
  @import './preflight.css';

  html {
    color-scheme: only light;
    background-color: theme(colors.background.light);
  }

  html.dark {
    background-color: theme(colors.background.dark);
  }

  .dark {
    color-scheme: only dark;
  }

  * {
    scrollbar-color: theme('colors.brand.DEFAULT') transparent;
    scrollbar-width: thin;
  }
  .dark * {
    scrollbar-color: theme('colors.gray.500') transparent;
    scrollbar-width: thin;
  }
  /* https://css-tricks.com/custom-scrollbars-in-webkit/ */
  *::-webkit-scrollbar {
    width: 0.7rem;
    height: 0.7rem;
  }
  *::-webkit-scrollbar-track {
    border-radius: 0.5rem;
    background-color: transparent;
  }
  *::-webkit-scrollbar-thumb {
    @apply bg-brand dark:bg-gray-500;
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
  }
  *::-webkit-scrollbar-corner {
    background: transparent;
  }
  svg[display='none'] {
    display: none;
  }
}

@tailwind components;

@layer components {
  /* @import 'quick-react-all-components/index.css'; */
  @import './hs.css';

  .rs-container.nomodal .rs__control {
    @apply dark:border-zinc-600 dark:bg-gray-700;
  }

  .rs-container .rs__control {
    @apply border border-neutral-300 bg-white hover:border-neutral-400 dark:border-zinc-500 dark:bg-gray-600 dark:hover:border-neutral-500;
  }

  .rs-container .rs__control--is-focused {
    @apply border-neutral-500 shadow-none hover:border-neutral-500 dark:border-neutral-400 dark:hover:border-neutral-400;
  }

  .rs-container .rs__control--is-disabled {
    @apply bg-gray-100 dark:border-gray-600 dark:bg-gray-700;
  }

  .rs-container .rs__menu {
    @apply border-2 border-neutral-300 bg-neutral-100 dark:border-neutral-600 dark:bg-neutral-700;
  }

  .rs-container .rs__option {
    @apply bg-neutral-100 text-neutral-600 hover:bg-neutral-200 dark:bg-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-800;
  }
  .rs-container .rs__option--is-focused {
    @apply bg-neutral-200 dark:bg-neutral-800;
  }

  .rs-container .rs__indicator-separator {
    @apply bg-neutral-400;
  }

  .rs-container .rs__input-container,
  .rs-container .rs__placeholder,
  .rs-container .rs__single-value {
    @apply text-neutral-600 dark:text-neutral-200;
  }

  .rs-container .rs__placeholder {
    @apply text-gray-400 dark:text-gray-500;
  }
  /* For use with React Select in base bg (Not modals)
  Add rs-base as prefix, and rs-base-container as className to the Select component
   */
  .rs-base-container .rs-base__control {
    @apply border border-neutral-300 bg-white hover:border-neutral-400 dark:border-zinc-600 dark:bg-gray-700 dark:hover:border-neutral-600;
  }

  .rs-base-container .rs-base__control--is-focused {
    @apply border-neutral-500 shadow-none hover:border-neutral-500 dark:border-neutral-500 dark:hover:border-neutral-500;
  }

  .rs-base-container .rs-base__control--is-disabled {
    @apply bg-gray-100 dark:border-gray-700 dark:bg-gray-800;
  }

  .rs-base-container .rs-base__menu {
    @apply border-2 border-neutral-300 bg-neutral-100 dark:border-neutral-600 dark:bg-neutral-700;
  }

  .rs-base-container .rs-base__option {
    @apply bg-neutral-100 text-neutral-600 hover:bg-neutral-200 dark:bg-neutral-700 dark:text-neutral-200 dark:hover:bg-neutral-800;
  }

  .rs-base-container .rs-base__indicator-separator {
    @apply bg-neutral-500;
  }

  .rs-base-container .rs-base__input-container,
  .rs-base-container .rs-base__placeholder,
  .rs-base-container .rs-base__single-value {
    @apply text-neutral-600 dark:text-neutral-200;
  }

  /* Select point map searchbox styles */
  .sPrS-container {
    @apply rounded-none;
  }

  .sPrS-container .sPrS__control {
    @apply rounded-none border-0;
  }

  .sPrS-container:first-child .sPrS__control {
    @apply rounded-tl sm:rounded-l;
  }

  .sPrS-container:nth-child(2) .sPrS__control {
    @apply rounded-tr sm:rounded-none;
  }

  .sPrS-container .sPrS__control--is-focused {
    @apply shadow-none;
  }
}

@tailwind utilities;

@layer utilities {
  .h-screen {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
  }

  .scroll-thin {
    scrollbar-width: thin;
  }

  .no-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .no-scroll::-webkit-scrollbar {
    display: none;
  }

  .circle {
    clip-path: circle(50% at 50% 50%);
  }
  .max-child-h-full > * {
    @apply max-h-full;
  }

  .child-min-h-0 > * {
    @apply min-h-0;
  }

  input[type='number'].no-arrows::-webkit-inner-spin-button,
  input[type='number'].no-arrows::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'].no-arrows {
    -moz-appearance: textfield;
  }

  .animation_dashboard {
    animation-duration: 2s;
    animation-delay: 0.3s;
    animation-name: fadeIn;
    animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
    animation-fill-mode: backwards;
  }

  .delay-1 {
    animation-delay: 0.4s;
  }
  .delay-2 {
    animation-delay: 0.5s;
  }
  .delay-3 {
    animation-delay: 0.6s;
  }
  .delay-4 {
    animation-delay: 0.7s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

html,
body {
  padding: 0;
  margin: 0;
}

html {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  height: 100dvh;
  width: 100vw;
  width: 100dvw;
}

body,
#__next {
  height: 100%;
  width: 100%;
}

body {
  @apply font-sans;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#__next {
  display: grid;
  grid-template-rows: auto 1fr;
}

#__next > #MainContainer {
  grid-row-start: 2;
}

.grecaptcha-badge {
  display: none !important;
  visibility: visible !important;
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  z-index: 999;
}

.grecaptcha-badge.show {
  display: block !important;
}

.grecaptcha-badge:hover {
  width: 256px !important;
}

.menu-button-container {
  /* https://alvarotrigo.com/blog/hamburger-menu-css/ */
  display: flex;
  height: 100%;
  width: 30px;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-button,
.menu-button::before,
.menu-button::after {
  @apply bg-gray-700 dark:bg-gray-300;
  display: block;
  position: absolute;
  height: 4px;
  width: 30px;
  transition: transform 400ms cubic-bezier(0.23, 1, 0.32, 1);
  border-radius: 2px;
}

.menu-button::before {
  content: '';
  margin-top: -8px;
}

.menu-button::after {
  content: '';
  margin-top: 8px;
}

.menu-button-container.active .menu-button::before {
  margin-top: 0px;
  transform: rotate(405deg);
}

.menu-button-container.active .menu-button {
  @apply bg-white dark:bg-gray-700;
}

.menu-button-container.active .menu-button::after {
  margin-top: 0px;
  transform: rotate(-405deg);
}

.child-w-full > * {
  @apply w-full;
}

.row-cursor-auto tbody tr {
  cursor: auto;
}

tbody tr:hover .row-button {
  @apply font-bold underline;
}

/* OP debugger */
/* * {
  background-color: hwb(138 1% 69% / 0.1);
} */
